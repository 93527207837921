export const userData = [
    { id: 1, name: 'Alice Johnson', email: 'alice@example.com', registered: new Date(2020, 0, 1), balance: 2345.75 },
    { id: 2, name: 'Bob Smith', email: 'bob@example.com', registered: new Date(2021, 1, 12), balance: 785.94 },
    { id: 3, name: 'Carol White', email: 'carol@example.com', registered: new Date(2019, 10, 22), balance: 13782.00 },
    { id: 4, name: 'Dave Brown', email: 'dave@example.com', registered: new Date(2018, 5, 30), balance: 550.50 },
    { id: 5, name: 'Eve Black', email: 'eve@example.com', registered: new Date(2020, 0, 15), balance: 2750.00 },
    { id: 6, name: 'Frank Green', email: 'frank@example.com', registered: new Date(2022, 3, 18), balance: 950.00 },
    { id: 7, name: 'Grace Hopper', email: 'grace@example.com', registered: new Date(2017, 8, 25), balance: 3240.00 },
    { id: 8, name: 'Henry Ford', email: 'henry@example.com', registered: new Date(2020, 11, 30), balance: 4851.20 },
    { id: 9, name: 'Isaac Newton', email: 'isaac@example.com', registered: new Date(2021, 9, 15), balance: 1920.85 },
    { id: 10, name: 'Julia Roberts', email: 'julia@example.com', registered: new Date(2019, 2, 20), balance: 11000.00 },
    { id: 11, name: 'Kevin Hart', email: 'kevin@example.com', registered: new Date(2018, 10, 31), balance: 1500.00 },
    { id: 12, name: 'Linda Carter', email: 'linda@example.com', registered: new Date(2016, 6, 9), balance: 3600.00 },
    { id: 13, name: 'Mike Tyson', email: 'mike@example.com', registered: new Date(2021, 1, 28), balance: 725.75 },
    { id: 14, name: 'Nancy Drew', email: 'nancy@example.com', registered: new Date(2020, 3, 15), balance: 5430.00 },
    { id: 15, name: 'Oprah Winfrey', email: 'oprah@example.com', registered: new Date(2019, 7, 30), balance: 22250.00 },
    { id: 16, name: 'Peter Parker', email: 'peter@example.com', registered: new Date(2018, 9, 17), balance: 1850.50 },
    { id: 17, name: 'Quentin Tarantino', email: 'quentin@example.com', registered: new Date(2016, 4, 29), balance: 9700.00 },
    { id: 18, name: 'Rachel Green', email: 'rachel@example.com', registered: new Date(2017, 11, 20), balance: 1630.00 },
    { id: 19, name: 'Steve Jobs', email: 'steve@example.com', registered: new Date(2015, 5, 5), balance: 32000.00 },
    { id: 20, name: 'Tim Cook', email: 'tim@example.com', registered: new Date(2014, 3, 24), balance: 21000.00 }
];
