import styled, { css } from 'styled-components';

interface TypographyProps {
  type: TextType;
  color?: string;
  children: React.ReactNode;
}

type TextType = 'title' | 'subtitle' | 'body' | 'caption';

const TypographyStyles = css<{ type: TextType; color?: string }>`
  ${({ type, color }) => {
    switch (type) {
      case 'title':
        return css`
          font-size: 24px;
          font-weight: bold;
          color: ${color || 'black'};
        `;
      case 'subtitle':
        return css`
          font-size: 18px;
          font-weight: 500;
          color: ${color || 'gray'};
        `;
      case 'body':
        return css`
          font-size: 14px;
          color: ${color || 'black'};
        `;
      case 'caption':
        return css`
          font-size: 12px;
          color: ${color || 'darkgray'};
        `;
      default:
        return '';
    }
  }}
`;

const Text = styled.span<TypographyProps>`
  ${TypographyStyles}
`;

export { Text };
