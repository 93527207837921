// import Button from '../../components/_ui/Button';
// import Input from '../../components/_ui/Input';
// import SearchableSelect from '../../components/_ui/SearchableSelect';
// import HttpClient from '../../services/api';
import { useEffect } from 'react';
import { Container } from './styles';
import Header from '../../components/_ui/Header';
import Footer from '../../components/_ui/Footer';
import Main from '../../components/_ui/LandingPage/Main';

export default function LandingPage() {
  // const router = useRouter();
  // const enrollmentRef = useRef<HTMLInputElement>(null);
  // const birthdayRef = useRef<HTMLInputElement>(null);
  // const [selectedGym, setSelectedGym] = useState<any>(null);
  // const [gyms, setGyms] = useState([]);

  useEffect(() => {
    const init = async () => {
      console.log('LandingPage mounted');
      // const response = await HttpClient.getInstance().getAxiosInstance().get('/gyms')
      // setGyms(response.data)
    };

    init();
  }, []);

  // async function handleSubmit() {
  //   console.log(enrollmentRef.current?.value)
  //   console.log(birthdayRef.current?.value)
  //   console.log(
  //     selectedGym
  //   )

  //   const response = await HttpClient.getInstance().getAxiosInstance().post('/sessions', {
  //     enrollment: enrollmentRef.current?.value || '12803',
  //     birthday: birthdayRef.current?.value || '1981-08-04',
  //   }, {
  //     headers: {
  //       'x-academy': selectedGym.label || 'A3 ACADEMIA'
  //     }
  //   })

  //   console.log(response.data)
  //   console.log(response.status)
  //   localStorage.setItem('token', response.data.token)
  //   console.log(`router.push('/home')`)
  // }

  return (
    <Container>
      <Header />
      <Main />
      <Footer />
    </Container>
  );
}
