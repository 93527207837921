import React from 'react';
import { Container } from './styles';
import { userData } from '../../../../mocks/users';
import Table from '../../Table';
import { format } from 'date-fns'; // Make sure to install date-fns if you haven't: npm install date-fns

const columns = [
  { key: 'name', label: 'Name', sortable: true, filterable: true },
  { key: 'email', label: 'Email', sortable: true, filterable: true },
  {
    key: 'registered',
    label: 'Registered',
    sortable: true,
    filterable: true,
    render: (user: any) => format(user.registered, 'PPP'), // Display formatted date
    sortFunction: (a: any, b: any) =>
      a.registered.getTime() - b.registered.getTime(),
  },
  {
    key: 'balance',
    label: 'Balance',
    sortable: true,
    render: (user: any) => `$${user.balance.toFixed(2)}`, // Display balance in USD format
    sortFunction: (a: any, b: any) => a.balance - b.balance,
  },
  {
    key: 'actions',
    label: 'Actions',
    render: (user: any) => (
      <>
        <button onClick={() => alert(`Editing ${user.name}`)}>Edit</button>
        <button onClick={() => alert(`Deleting ${user.name}`)}>Delete</button>
      </>
    ),
  },
];

const Main: React.FC = () => {
  return (
    <Container>
      <h1>User List</h1>
      <Table data={userData} columns={columns} />
    </Container>
  );
};

export default Main;
