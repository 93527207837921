import React from 'react';
import { Navigate } from 'react-router-dom';
import { usePageContext } from '../hooks';
import Header from '../components/_ui/Header';

const RequiredAuth: React.FC<any> = ({ children }): any => {
  const { isLogged } = usePageContext();

  if (!isLogged) {
    return <Navigate to="/" replace />;
  }

  return (
    <>
      <Header />
      {children}
    </>
  );
};

export default RequiredAuth;
