import React from 'react';
import { Container, Content } from './styles';
import GridScaper from '../GridScaper';
import Typography from '../Typography';

const Footer: React.FC = () => {
  return (
    <Container>
      <GridScaper>
        <Content>
          <Typography type="caption" color="#fff">
            1.0.1
          </Typography>
        </Content>
      </GridScaper>
    </Container>
  );
};

export default Footer;
