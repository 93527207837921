import React from 'react';
import { Text } from './styles';

type TextType = 'title' | 'subtitle' | 'body' | 'caption';

interface TypographyProps {
  type: TextType;
  color?: string;
  children: React.ReactNode;
}

const Typography: React.FC<TypographyProps> = ({ type, color, children }) => {
  return (
    <Text type={type} color={color}>
      {children}
    </Text>
  );
};

export default Typography;
