import React, { useState } from 'react';
import styled from 'styled-components';

interface Column<T> {
  key: string;
  label: string;
  render?: (item: T) => React.ReactNode;
  sortable?: boolean;
  filterable?: boolean;
  sortFunction?: (a: T, b: T) => number;
}

interface TableProps<T> {
  data: T[];
  columns: Column<T>[];
  pageSize?: number;
}

const TableStyles = styled.div`
  .table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      padding: 8px;
      border: 1px solid #ccc;
      text-align: left;
    }

    th {
      background-color: #f4f4f4;
    }
  }

  .pagination {
    padding: 10px 0;
    display: flex;
    justify-content: center;
    gap: 10px;
  }

  button {
    padding: 5px 10px;
    border-radius: 5px;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;

    &:disabled {
      background-color: #ccc;
    }
  }
`;

const Table = <T extends Record<string, any>>({
  data,
  columns,
  pageSize = 10,
}: TableProps<T>) => {
  const [filteredData, setFilteredData] = useState(data);
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: string;
  } | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(filteredData.length / pageSize);

  const currentPageData = filteredData.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize,
  );

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const sortData = (
    key: string,
    customSortFunction?: (a: T, b: T) => number,
  ) => {
    let sortedData = [...filteredData];
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'ascending'
    ) {
      setSortConfig({ key, direction: 'descending' });
      sortedData = sortedData.sort(
        customSortFunction
          ? (a, b) => customSortFunction(b, a)
          : (a, b) => (a[key] > b[key] ? -1 : 1),
      );
    } else {
      setSortConfig({ key, direction: 'ascending' });
      sortedData = sortedData.sort(
        customSortFunction
          ? customSortFunction
          : (a, b) => (a[key] > b[key] ? 1 : -1),
      );
    }
    setFilteredData(sortedData);
  };

  const handleFilter = (key: string, value: string) => {
    if (!value) {
      setFilteredData(data);
    } else {
      setFilteredData(
        data.filter((item) =>
          item[key].toString().toLowerCase().includes(value.toLowerCase()),
        ),
      );
    }
  };

  return (
    <TableStyles>
      <table className="table">
        <thead>
          <tr>
            {columns.map((col) => (
              <th key={col.key}>
                {col.label}
                {col.sortable && (
                  <button onClick={() => sortData(col.key, col.sortFunction)}>
                    {sortConfig && sortConfig.key === col.key
                      ? sortConfig.direction === 'ascending'
                        ? '🔼'
                        : '🔽'
                      : '↕'}
                  </button>
                )}
              </th>
            ))}
          </tr>
          <tr>
            {columns
              .filter((col) => col.filterable)
              .map((col) => (
                <th key={col.key}>
                  <input
                    type="text"
                    onChange={(e) => handleFilter(col.key, e.target.value)}
                    placeholder={`Filter by ${col.label}`}
                  />
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {currentPageData.map((item, index) => (
            <tr key={index}>
              {columns.map((col) => (
                <td key={col.key}>
                  {col.render ? col.render(item) : item[col.key]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination">
        <button onClick={prevPage} disabled={currentPage === 1}>
          Previous
        </button>
        <button onClick={nextPage} disabled={currentPage === totalPages}>
          Next
        </button>
      </div>
    </TableStyles>
  );
};

export default Table;
