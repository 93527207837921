import styled from 'styled-components';

const Container = styled.section`
  width: 100%;
  height: 92vh;
  box-sizing: border-box;
  background-color: #f0f0f0;
`;

export { Container };
